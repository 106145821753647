import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "game-loop-animation-with-a-bouncy-ball"
    }}>{`Game loop animation with a bouncy ball`}</h1>
    <Vimeo id={436112124} mdxType="Vimeo" />
    <p>{`Let's get our feet wet with my favorite childhood example: a bouncing ball.`}</p>
    <p>{`I must have built dozens of them back in my
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Turbo_Pascal"
      }}>{`Turbo Pascal`}</a>{` days using
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Borland_Graphics_Interface"
      }}>{`BGI`}</a>{`. Endless
afternoons playing with different parameters in my bouncy ball examples.`}</p>
    <p>{`Yes, those Turbo Pascal and BGI are from the 80's. No, I'm not that old. I
started young and with old equipment. Coding for DOS is easier when you're a
kid than coding for Windows 95.`}</p>
    <p>{`Try to figure it out on your own first. Then watch my solution. Best way to learn :)`}</p>
    <p>{`Here's a sandbox I prepared for you earlier:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-bouncing-ball-hooks-unsolved-eotik",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Remember that gravity is an acceleration pointing down. It impacts the `}<em parentName="p">{`speed`}</em>{` of your ball, not its position directly.`}</p>
    <ol>
      <li parentName="ol">{`Render the ball`}</li>
      <li parentName="ol">{`Use an effect to start a timer with `}<inlineCode parentName="li">{`d3.timer`}</inlineCode>{` (it's like setInterval but better)`}</li>
      <li parentName="ol">{`Ensure you clean up with `}<inlineCode parentName="li">{`timer.stop()`}</inlineCode></li>
      <li parentName="ol">{`Move the ball on each timer iteration (also known as a tick)`}</li>
      <li parentName="ol">{`Adjust the ball's speed to simulate gravity`}</li>
    </ol>
    <p>{`How do you get the bounce effect at `}<inlineCode parentName="p">{`max_h`}</inlineCode>{`? Look at the direction `}<inlineCode parentName="p">{`vY`}</inlineCode>{` points.`}</p>
    <h2 {...{
      "id": "my-solution"
    }}>{`My solution`}</h2>
    <Vimeo id={436112958} mdxType="Vimeo" />
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-bouncing-ball-hooks-solved-d5ize?file=/src/BouncingBall.js",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`The key part of my solution is this `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` that runs a game loop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`useEffect(() => {
  function gameLoop() {
    setBall((ball) => {
      let { y, vy } = ball

      if (y > max_h) {
        vy = -vy
      }

      return {
        y: y + vy,
        vy: vy + 0.1,
      }
    })
  }

  const t = d3.timer(gameLoop)
  return () => t.stop()
}, [])
`}</code></pre>
    <p>{`That's where all the important things happen.`}</p>
    <p>{`Start the timer on component mount, make sure it stops when the effect re-runs. Game loop itself is a function that takes current ball state and updates it with middle school physics:`}</p>
    <ul>
      <li parentName="ul">{`if ground reached, invert velocity`}</li>
      <li parentName="ul">{`add velocity to position`}</li>
      <li parentName="ul">{`add gravity to velocity`}</li>
    </ul>
    <p>{`And you get a bouncy ball ✌️`}</p>
    <p>{`While powerful, this technique is tedious for most applications. We'll look at using transitions next. That way D3 can do the hard work for us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      